import {
  FETCH_SURVEYWIZARD_PARTICIPATION_START,
  FETCH_SURVEYWIZARD_PARTICIPATION_SUCCESS,
  FETCH_SURVEYWIZARD_PARTICIPATION_FAIL,

  SURVEYWIZARD_UPDATE_PARTICIPATION_COLUMN_LIST,
  SURVEYWIZARD_DESELECT_PARTICIPATION_COLUMN,

  SURVEY_WIZARD_RESPONSES_FETCH_RESPONSES_STARTED,
  SURVEY_WIZARD_RESPONSES_FETCH_RESPONSES_SUCCESS,
  SURVEY_WIZARD_RESPONSES_FETCH_RESPONSES_FAILED,

  SURVEY_WIZARD_FETCH_SURVEY_LIST_START,
  SURVEY_WIZARD_FETCH_SURVEY_LIST_SUCCESS,
  SURVEY_WIZARD_FETCH_SURVEY_LIST_FAIL,

  FETCH_SURVEY_WIZARD_PROJECT_DETAILS_START,
  FETCH_SURVEY_WIZARD_PROJECT_DETAILS_SUCCCESS,
  FETCH_SURVEY_WIZARD_PROJECT_DETAILS_FAIL,

  SURVEY_WIZARD_FETCH_HRIS_FILE_START,
  SURVEY_WIZARD_FETCH_HRIS_FILE_SUCCESS,
  SURVEY_WIZARD_FETCH_HRIS_FILE_FAIL,

  SURVEY_WIZARD_MODIFY_PROJECT_DATA_START,
  SURVEY_WIZARD_MODIFY_PROJECT_DATA_SUCCESS,
  SURVEY_WIZARD_MODIFY_PROJECT_DATA_FAIL,

  SET_SETUP_LOCAL_STATE_DATA,

  SETUP_SEND_PREVIEW_MAIL_START,
  SETUP_SEND_PREVIEW_MAIL_SUCCESS,
  SETUP_SEND_PREVIEW_MAIL_FAIL,

  EDIT_SURVEY_MARK_AS_OUTCOME,
  EDIT_SURVEY_MOVE_UP_OR_MOVE_DOWN_TOPIC,
  EDIT_SURVEY_DELETE_TOPIC,
  EDIT_SURVEY_TOPICS_DRAG_AND_DROP,
  EDIT_SURVEY_TOPIC_OPTION_CHANGE,
  EDIT_SURVEY_ADD_TOPIC,
  EDIT_SURVEY_ADD_STATEMENT,
  EDIT_SURVEY_DELETE_STATEMENT,
  EDIT_SURVEY_ON_BLUR_INPUTBOX,
  EDIT_SURVEY_ON_BLUR_DESCRIPTION_INPUTBOX,
  EDIT_SURVEY_ADD_CUSTOMTOPIC,
  EDIT_SURVEY_ADD_CUSTOM_TOPIC_STATEMENT_STATEMENT,
  EDIT_SURVEY_MOVE_UP_OR_MOVE_DOWN_OPEN_ENDED_QUESTIONS,
  EDIT_SURVEY_ON_BLUR_OPEN_ENDED_HEADING_INPUTBOX,
  EDIT_SURVEY_DELETE_OPEN_ENDED_QUESTION,
  EDIT_SURVEY_ADD_OPEN_ENDED_QUESTIONS,
  EDIT_SURVEY_ONCHANGE_PROJECT_NAME,
  EDIT_SURVEY_ONCHANGE_ABOUT_SURVEY,
  EDIT_SURVEY_MOVE_UP_OR_MOVE_DOWN_DEMOGRAPHICS_QUESTIONS,
  EDIT_SURVEY_DEMOGRAPHICS_DRAG_AND_DROP,
  EDIT_SURVEY_ADD_DEMOGRAPHIC_ANSWER_OPTION,
  EDIT_SURVEY_ON_BLUR_DEMOGRAPHIC_HEADING,
  EDIT_SURVEY_ON_BLUR_DEMOGRAPHIC_ANSWER_OPTION,
  EDIT_SURVEY_DELETE_DEMOGRAPHIC_QUESTION,
  EDIT_SURVEY_DELETE_DEMOGRAPHIC_QUESTION_ANSWER,
  EDIT_SURVEY_UNMARK_OUTCOME_TOPIC,
  EDIT_SURVEY_ADD_DEMOGRAPHIC_QUESTION,

  NEW_WIZARD_UPLOAD_TRANSLATED_FILE_FAILED,
  NEW_WIZARD_UPLOAD_TRANSLATED_FILE_START,
  NEW_WIZARD_UPLOAD_TRANSLATED_FILE_SUCCESS,
  NEW_WIZARD_CHECK_TRANSLATION_STATUS_START,
  NEW_WIZARD_CHECK_TRANSLATION_STATUS_SUCCESS,
  NEW_WIZARD_CHECK_TRANSLATION_STATUS_FAILED,

  ADD_DESCRIPTIVE_QUESTION,

  EDIT_DESCRIPTIVE_TEXT_HEADING,
  ONCHANGE_QUESTION,
  EDIT_SURVEY_ON_BLUR_DEMOGRAPHIC_QUESTION_LABEL,
  EDIT_SURVEY_ON_BLUR_OPEN_ENDED_QUESTION_LABEL_INPUTBOX,
  EDIT_SURVEY_MOVE_UP_OR_MOVE_DOWN_MULTIPLE_SELECTION_QUESTIONS,
  EDIT_SURVEY_MULTIPLE_SELECTION_QUESTIONS_DRAG_AND_DROP,
  EDIT_SURVEY_ON_BLUR_MULTIPLE_SELECTION_HEADING,
  EDIT_SURVEY_ON_BLUR_MULTIPLE_SELECTION_QUESTION_LABEL,
  EDIT_SURVEY_ON_BLUR_MULTIPLE_SELECTION_ANSWER_OPTION,
  EDIT_SURVEY_DELETE_MULTIPLE_SELECTION_QUESTION_ANSWER,
  EDIT_SURVEY_DELETE_MULTIPLE_SELECTION_QUESTION,
  EDIT_SURVEY_ADD_MULTIPLE_SELECTION_QUESTION,
  EDIT_SURVEY_ADD_MULTIPLE_SELECTION_QUESTIONS_ANSWER_OPTION,
  ON_CHANGE_LOGO,

  ADD_FOLLOW_UP_QUESTIONS,
  DELETE_FOLLOW_UP_QUESTIONS,

  ON_BLUR_OPEN_ENDED_DESCRIPTION_FOLLOW_UP_QUESTIONS,
  ON_BLUR_OPEN_ENDED_HEADING_FOLLOW_UP_QUESTIONS,
  ON_BLUR_MATRIX_FOLLOW_UP_QUESTIONS,
  ON_CHANGE_FOLLOW_UP_MATRIX_QUESTION_SCALE,
  ON_CHANGE_FOLLOW_UP_QUESTION_CONDITION,
  ON_BLUR_OPEN_ENDED_HEADING_FOLLOW_UP_QUESTIONS_LEVEL,
  ON_BLUR_OPEN_ENDED_DESCRIPTION_FOLLOW_UP_QUESTIONS_LEVEL,
  ADD_QUESTION_LEVEL_MATRIX_FOLLOW_UP_QUESTIONS,
  ADD_QUESTION_LEVEL_OPEN_ENDED_FOLLOW_UP_QUESTIONS,
  DELETE_QUESTION_LEVEL_FOLLOW_UP,

  FETCH_HRIS_FILTERS_START,
  FETCH_HRIS_FILTERS_SUCCESS,
  FETCH_HRIS_FILTERS_FAIL,
  ADD_CONDITIONAL_DISPLAY_TOPICS,
  ON_CHANGE_DISPLAY_CONDITION,
  DELETE_CONDITIONAL_DISPLAY_TOPICS,
  SURVEY_EDITOR_OPTIONS_DRAG_END,
  SURVEY_EDITOR_OPTIONS_DELETE,
  SURVEY_EDITOR_OPTIONS_ADD,
  SURVEY_EDITOR_HANDLE_CHANGE_OPTIONS,
  SURVEY_EDITOR_LIKERT_DIMENSION_ORDESCRIPTION_CHANGE,
  SURVEY_EDITOR_LIKERT_DRAG_AND_DROP,
  SURVEY_EDITOR_LIKERT_REQUIRED_CHANGE,
  SURVEY_EDITOR_LIKERT_CHANGE_OUTCOME_VARIABLE,
  SURVEY_EDITOR_TEXT_ENTRY_CHANGE_QUESTION_AND_LABEL,
  SURVEY_EDITOR_HANDLE_MULTIOPTION_TOGGLE,
  SURVEY_EDITOR_LIKERT_OPTION_CHANGE,
  SURVEY_EDITOR_LIKERT_OPTION_CHANGE_LABEL,
  SURVEY_EDITOR_ADD_QUESTIONS_FROM_LIBRARY,
  SURVEY_EDITOR_HANDLE_CHANGE_QUESTION,
  SURVEY_EDITOR_HANDLE_DELETE_BLOCK,
  SURVEY_EDITOR_HANDLE_CREATE_DUPLICATE,
  SURVEY_EDITOR_HANDLE_BLOCK_DRAG_END,
  SURVEY_EDITOR_LIKERT_CHANGE_OPTIONS_COUNT,
  SURVEY_EDITOR_LIKERT_QUESTION_LABEL_CHANGE,
  SURVEY_EDITOR_LIKERT_QUESTION_ADD_FOLLOWUP,
  SURVEY_EDITOR_LIKERT_QUESTION_DELETE_QUESTION,
  SURVEY_EDITOR_LIKERT_QUESTION_ADD_QUESTION,
  SURVEY_EDITOR_LIKERT_ADD_DISPLAY_CONDITION,
  SURVEY_EDITOR_LIKERT_CHANGE_DISPLAY_CONDITION,
  SURVEY_EDITOR_LIKERT_DELETE_DISPLAY_CONDITION,
  SURVEY_EDITOR_LIKERT_QUESTION_DELETE_FOLLOWUP,
  SURVEY_EDITOR_LIKERT_QUESTION_CHANGE_FOLLOWUP_CONDITION,
  SURVEY_EDITOR_LIKERT_QUESTION_CHANGE_FOLLOWUP_LABEL,
  SURVEY_EDITOR_RATING_RIGHT_CHANGE_OPTION_COUNT_AND_SHAPE,
  SURVEY_EDITOR_RATING_RIGHT_CHANGE_QUESTION_AND_LABEL,
  SURVEY_EDITOR_RANKING_RIGHT_CHANGE_QUESTION_AND_LABEL,
  SURVEY_EDITOR_RANKING_DRAG_AND_DROP,
  SURVEY_EDITOR_MATRIX_CHANGE_LABELS,
  SURVEY_EDITOR_MATRIX_ADD_ROW_OR_COL,
  SURVEY_EDITOR_MATRIX_REMOVE_ROW_OR_COL,
  SURVEY_EDITOR_ADD_BLOCK,
  SURVEY_EDITOR_RANKING_DELETE_OR_ADD_OPTION,
  SURVEY_EDITOR_ADD_IMAGES_FROM_LIBRARY,
  SURVEY_EDITOR_COPY_TO_ANSWERING_PAGE,
  SURVEY_EDITOR_NET_PROMOTER_CHANGE_QUESTION_AND_LABEL,
  SURVEY_EDITOR_DESCRIPTIVE_SECTION_CHANGE,
  SURVEY_EDITOR_CHANGE_TOPIC,
  SURVEY_EDITOR_LIKERT_CHANGE_NA_OPTION,
  SURVEY_EDITOR_MATRIX_CHANGE_NA_OPTION,
  SURVEY_EDITOR_HANDLE_TOGGLE_WRITE_IN_OPTION,
  SURVEY_EDITOR_HANDLE_ADD_FOLLOWUP_QUESTION,
  SURVEY_EDITOR_HANDLE_ADD_CONDITIONAL_FOLLOWUP,
  SURVEY_EDITOR_MULTIPLE_CHOICE_CHANGE_FOLLOWUP_LABEL,
  SURVEY_EDITOR_MULTIPLE_CHOICE_DELETE_FOLLOWUP,
  SURVEY_EDITOR_HANDLE_MULTIPLE_CHOICE_CONDITIONAL_FOLLOWUP_LABEL,
  SURVEY_EDITOR_HANDLE_MULTIPLE_CHOICE_CONDITIONAL_FOLLOWUP,
  SURVEY_EDITOR_HANDLE_MULTIPLE_CHOICE_CONDITIONAL_FOLLOWUP_CHANGE,
  HANDLE_UPDATE_REDIRECT_VALUE_QUESTION,
  FILE_UPLOAD_STATUS_FAIL,
  FILE_UPLOAD_STATUS_SUCCESS,
  FILE_UPLOAD_STATUS_START,
  FETCH_COHORT_CHILD_REMAP,
  GET_NORM_BENCHMARK_VALUE_SUCCESS,
  GET_NORM_BENCHMARK_VALUE_FAILED,
  GET_NORM_BENCHMARK_VALUE_STARTED,

  SET_NORM_BENCHMARK_VALUE_SUCCESS,
  SET_NORM_BENCHMARK_VALUE_FAILED,
  SET_NORM_BENCHMARK_VALUE_STARTED,
  HANDLE_CHANGE_BENCHMARK_NORMS
} from "../../constants";
import { Default } from "./InitialState/index";
import {
  surveywizardParticipationDataStart,
  surveywizardParticipationDataSuccess,
  surveywizardParticipationDataFailed,
  surveywizardParticipationUpdateSelectedColumn,
  surveywizardDeselectParticipationColumns,
  fetchResponsesStart,
  fetchResponsesSuccess,
  fetchResponsesFailed,
  fetchSurveyWizardSurveyListStart,
  fetchSurveyWizardSurveyListSuccess,
  fetchSurveyWizardSurveyListFail,
  fetchSurveyWizardProjectDetailsStart,
  fetchSurveyWizardProjectDetailsSuccess,
  fetchSurveyWizardProjectDetailsFail,
  surveyWizardFetchHrisFileStart,
  surveyWizardFetchHrisFileSuccess,
  surveyWizardFetchHrisFileFail,
  modifyProjectDataStart,
  modifyProjectDataSuccess,
  modifyProjectDataFail,
  setLocalStateData,
  markAsOutCome,
  moveUporMoveDown,
  deleteTopic,
  topicsDragAndDrop,
  topicOptionChange,
  addTopic,
  addStatement,
  deleteStatement,
  onBlurInput,
  onBlurInputDescripion,
  addCustomTopic,
  addCutomTopicStatement,
  moveUporMoveDownOpenEndedQuestions,
  onBlurOpenEndedHeadingInput,
  deleteOpenEndedQuestion,
  addOpenEndedQuestions,
  onChangeProjectName,
  onChnageAboutSurvey,
  moveUporMoveDownDemographicsQuestions,
  demographicsDragAndDrop,
  addDemographicAnswerOption,
  onBlurDemographicHeading,
  onBlurDemographicAnswerOption,
  deleteDemographicQuestion,
  deleteDemographicQuestionAnswer,
  unMarkOutCome,
  addDemographicQuestion,
  sendPreviewMailStarted,
  sendPreviewMailSuccess,
  sendPreviewMailFailed,
  uploadTranslatedQuestionsStart,
  uploadTranslatedQuestionsSuccess,
  uploadTranslatedQuestionsFailed,
  checkTranslatedQuestionsStart,
  checkTranslatedQuestionsSuccess,
  checkTranslatedQuestionsFailed,
  addDescriptiveQuestions,
  editDescriptiveQuestionLable,
  editDescriptiveText,
  onBlurDemographicQuestionLabel,
  onBlurOpenEndedQuestionLabel,
  moveUporMoveDownMultipleSelectedQuestions,
  multipleSelectionDragAndDrop,
  addMultipleSelectionAnswerOption,
  onBlurMultipleSelectionHeading,
  onBlurMultipleSelectionQuestionLabel,
  onBlurMultipleSelectionAnswerOption,
  deleteMultipleSelectionQuestionAnswer,
  addMultipleSelectionQuestion,
  deleteMultipleSelectionQuestion,
  addFollowUpQuestion,
  deleteFollowUpQuestion,
  onBlurOpenEndedHeading,
  onBlurOpenEndedDescription,
  onChangeLogo,
  onBlurFollowUpMatrixQuestion,
  onChangeMatrixQuestionScale,
  onChangeFollowUpQuestionCondition,
  addQuestionLevelMatrixFollowUp,
  addQuestionLevelOpenEndedFollowUp,
  onBlurQuestionLevelOpenEndedHeading,
  onBlurQuestionLevelOpenEndedDescription,
  deleteQuestionLevelFollowUp,
  fetchHRISDataStart,
  fetchHRISDataSuccess,
  fetchHRISDataFail,
  addConditionalDisplayTopics,
  onChangeDisplayCondition,
  onDeleteDisplayCondition,
  handleDragEndDemographicsOption,
  handleDeleteOption,
  addMultipleChoiceOption,
  handleChangeLabel,
  onChangeLikertDimensionOrDescription,
  likertDragAndDrop,
  likertRequiredChange,
  likertOutComeVariableChange,
  onChangeTextEntryQuestionAndLabel,
  handleChangeMultiOptionToggle,
  likertOptionChange,
  likertOptionLabelChange,
  handleAddFromLibrary,
  handleChangeMultiOptionChange,
  handleDeleteBlock,
  handleDuplicateBlock,
  handleBlockDragEnd,
  likertChnageOptionsCount,
  likertQuestionLabelChange,
  likertQuestionAddFollowUp,
  likertQuestionAddQuestion,
  likertQuestionDeleteQuestion,
  onSaveConditionalDisplayLikert,
  onDeleteDisplayConditionLikert,
  onChangeDisplayConditionLikert,
  likertQuestionDeleteFollowUp,
  likertQuestionChangeFollowUpCondition,
  likertQuestionChangeFollowUpLabel,
  ratingSectionOptionCountAndShapeChange,
  ratingSectionChangeQuestionAndQuestionLabel,
  rankingSectionChangeQuestionAndQuestionLabel,
  rankingSectionDragAndDrop,
  matrixLabelChange,
  matrixAddColumOrRow,
  matrixRemoveColumOrRow,
  addQuestionBlock,
  rankingSectionAddOrDeleteOption,
  handleAddImage,
  changeDescriptiveText,
  likertShowTopicChange,
  likertNaOptionChange,
  handleNaOptionSelect,
  handleChangeToggleWriteInOption,
  addFollowUpQuestionForMultiChoice,
  multipleOptionFollowUpLabelChange,
  multipleChoiceDeleteFollowUp,
  addConditionalFollowUpForMultiChoice,
  multipleChoiceQuestionChangeFollowUpLabel,
  multipleChoiceQuestionDeleteFollowUp,
  multipleChoiceQuestionChangeFollowUpCondition,
  redirectConditionValue,
  fileUploadStatusFail,
  fileUploadStatusSuccess,
  fileUploadStatusStart,
  fetchCohortChildListSuccess,
  getBenchmarkDetailsStarted,
  getBenchmarkDetailsSuccess,
  getBenchmarkDetailsFail,
  handleChangeBenchmarkNorms,
} from "./Helper";
import { netPromoterSectionChangeQuestionAndQuestionLabel } from "./Helper/SurveyEditPageHelper/NetPromoterHelper";
import { setBenchmarkDetailsFail, setBenchmarkDetailsStarted, setBenchmarkDetailsSuccess } from "./Helper/setBenchmarkNormData";




const NewSurveyWizardReducer = (state = Default(), action) => {
  switch (action.type) {
    case FETCH_SURVEYWIZARD_PARTICIPATION_START:
      return surveywizardParticipationDataStart(state, action.payload);
    case FETCH_SURVEYWIZARD_PARTICIPATION_SUCCESS:
      return surveywizardParticipationDataSuccess(state, action.payload);
    case FETCH_SURVEYWIZARD_PARTICIPATION_FAIL:
      return surveywizardParticipationDataFailed(state, action.payload);

    case SURVEYWIZARD_UPDATE_PARTICIPATION_COLUMN_LIST:
      return surveywizardParticipationUpdateSelectedColumn(state, action.payload);
    case SURVEYWIZARD_DESELECT_PARTICIPATION_COLUMN:
      return surveywizardDeselectParticipationColumns(state);

    case SURVEY_WIZARD_RESPONSES_FETCH_RESPONSES_STARTED:
      return fetchResponsesStart(state, action.payload);
    case SURVEY_WIZARD_RESPONSES_FETCH_RESPONSES_SUCCESS:
      return fetchResponsesSuccess(state, action.payload);
    case SURVEY_WIZARD_RESPONSES_FETCH_RESPONSES_FAILED:
      return fetchResponsesFailed(state, action.payload);

    case SURVEY_WIZARD_FETCH_SURVEY_LIST_START:
      return fetchSurveyWizardSurveyListStart(state, action.payload);
    case SURVEY_WIZARD_FETCH_SURVEY_LIST_SUCCESS:
      return fetchSurveyWizardSurveyListSuccess(state, action.payload);
    case SURVEY_WIZARD_FETCH_SURVEY_LIST_FAIL:
      return fetchSurveyWizardSurveyListFail(state, action.payload);

    case FETCH_COHORT_CHILD_REMAP:
      return fetchCohortChildListSuccess(state,action.data);

    case FETCH_SURVEY_WIZARD_PROJECT_DETAILS_START:
      return fetchSurveyWizardProjectDetailsStart(state, action.payload);
    case FETCH_SURVEY_WIZARD_PROJECT_DETAILS_SUCCCESS:
      return fetchSurveyWizardProjectDetailsSuccess(state, action.payload);
    case FETCH_SURVEY_WIZARD_PROJECT_DETAILS_FAIL:
      return fetchSurveyWizardProjectDetailsFail(state, action.payload);

    case FILE_UPLOAD_STATUS_START:
      return fileUploadStatusStart(state,action.payload);
    case FILE_UPLOAD_STATUS_SUCCESS:
      return fileUploadStatusSuccess(state,action.payload);
    case FILE_UPLOAD_STATUS_FAIL:
      return fileUploadStatusFail(state,action.payload)

    case SURVEY_WIZARD_FETCH_HRIS_FILE_START:
      return surveyWizardFetchHrisFileStart(state, action);
    case SURVEY_WIZARD_FETCH_HRIS_FILE_SUCCESS:
      return surveyWizardFetchHrisFileSuccess(state, action);
    case SURVEY_WIZARD_FETCH_HRIS_FILE_FAIL:
      return surveyWizardFetchHrisFileFail(state, action);

    case SURVEY_WIZARD_MODIFY_PROJECT_DATA_START:
      return modifyProjectDataStart(state, action.payload);
    case SURVEY_WIZARD_MODIFY_PROJECT_DATA_SUCCESS:
      return modifyProjectDataSuccess(state, action);
    case SURVEY_WIZARD_MODIFY_PROJECT_DATA_FAIL:
      return modifyProjectDataFail(state, action);

    case SET_SETUP_LOCAL_STATE_DATA:
      return setLocalStateData(state, action.payload)

    case EDIT_SURVEY_MARK_AS_OUTCOME:
      return markAsOutCome(state, action.payload)
    case EDIT_SURVEY_MOVE_UP_OR_MOVE_DOWN_TOPIC:
      return moveUporMoveDown(state, action.payload)
    case EDIT_SURVEY_DELETE_TOPIC:
      return deleteTopic(state, action.payload)
    case EDIT_SURVEY_TOPICS_DRAG_AND_DROP:
      return topicsDragAndDrop(state, action.payload)
    case EDIT_SURVEY_TOPIC_OPTION_CHANGE:
      return topicOptionChange(state, action.payload)
    case EDIT_SURVEY_ADD_TOPIC:
      return addTopic(state, action.payload)
    case EDIT_SURVEY_ADD_STATEMENT:
      return addStatement(state, action.payload)
    case EDIT_SURVEY_DELETE_STATEMENT:
      return deleteStatement(state, action.payload)
    case EDIT_SURVEY_ON_BLUR_INPUTBOX:
      return onBlurInput(state, action.payload)
    case EDIT_SURVEY_ON_BLUR_DESCRIPTION_INPUTBOX:
      return onBlurInputDescripion(state, action.payload)
    case EDIT_SURVEY_ADD_CUSTOMTOPIC:
      return addCustomTopic(state, action.payload)
    case EDIT_SURVEY_ADD_CUSTOM_TOPIC_STATEMENT_STATEMENT:
      return addCutomTopicStatement(state, action.payload)
    case EDIT_SURVEY_MOVE_UP_OR_MOVE_DOWN_OPEN_ENDED_QUESTIONS:
      return moveUporMoveDownOpenEndedQuestions(state, action.payload)
    case EDIT_SURVEY_ON_BLUR_OPEN_ENDED_HEADING_INPUTBOX:
      return onBlurOpenEndedHeadingInput(state, action.payload)
    case EDIT_SURVEY_ON_BLUR_OPEN_ENDED_QUESTION_LABEL_INPUTBOX:
      return onBlurOpenEndedQuestionLabel(state, action.payload)
    case EDIT_SURVEY_DELETE_OPEN_ENDED_QUESTION:
      return deleteOpenEndedQuestion(state, action.payload)
    case EDIT_SURVEY_ADD_OPEN_ENDED_QUESTIONS:
      return addOpenEndedQuestions(state, action.payload)
    case EDIT_SURVEY_ONCHANGE_PROJECT_NAME:
      return onChangeProjectName(state, action.payload)
    case EDIT_SURVEY_ONCHANGE_ABOUT_SURVEY:
      return onChnageAboutSurvey(state, action.payload)
    case EDIT_SURVEY_MOVE_UP_OR_MOVE_DOWN_DEMOGRAPHICS_QUESTIONS:
      return moveUporMoveDownDemographicsQuestions(state, action.payload)
    case EDIT_SURVEY_DEMOGRAPHICS_DRAG_AND_DROP:
      return demographicsDragAndDrop(state, action.payload)
    case EDIT_SURVEY_ADD_DEMOGRAPHIC_ANSWER_OPTION:
      return addDemographicAnswerOption(state, action.payload)
    case EDIT_SURVEY_ON_BLUR_DEMOGRAPHIC_HEADING:
      return onBlurDemographicHeading(state, action.payload)
    case EDIT_SURVEY_ON_BLUR_DEMOGRAPHIC_QUESTION_LABEL:
      return onBlurDemographicQuestionLabel(state, action.payload)
    case EDIT_SURVEY_ON_BLUR_DEMOGRAPHIC_ANSWER_OPTION:
      return onBlurDemographicAnswerOption(state, action.payload)
    case EDIT_SURVEY_DELETE_DEMOGRAPHIC_QUESTION:
      return deleteDemographicQuestion(state, action.payload)
    case EDIT_SURVEY_DELETE_DEMOGRAPHIC_QUESTION_ANSWER:
      return deleteDemographicQuestionAnswer(state, action.payload)
    case EDIT_SURVEY_UNMARK_OUTCOME_TOPIC:
      return unMarkOutCome(state, action.payload)
    case EDIT_SURVEY_ADD_DEMOGRAPHIC_QUESTION:
      return addDemographicQuestion(state, action.payload)

    case SETUP_SEND_PREVIEW_MAIL_START:
      return sendPreviewMailStarted(state, action.payload);
    case SETUP_SEND_PREVIEW_MAIL_SUCCESS:
      return sendPreviewMailSuccess(state, action.payload);
    case SETUP_SEND_PREVIEW_MAIL_FAIL:
      return sendPreviewMailFailed(state, action.payload);

    case NEW_WIZARD_UPLOAD_TRANSLATED_FILE_START:
      return uploadTranslatedQuestionsStart(state, action.payload);
    case NEW_WIZARD_UPLOAD_TRANSLATED_FILE_SUCCESS:
      return uploadTranslatedQuestionsSuccess(state, action.payload)
    case NEW_WIZARD_UPLOAD_TRANSLATED_FILE_FAILED:
      return uploadTranslatedQuestionsFailed(state, action.payload)
    case NEW_WIZARD_CHECK_TRANSLATION_STATUS_START:
      return checkTranslatedQuestionsStart(state, action.payload)
    case NEW_WIZARD_CHECK_TRANSLATION_STATUS_SUCCESS:
      return checkTranslatedQuestionsSuccess(state, action.payload)
    case NEW_WIZARD_CHECK_TRANSLATION_STATUS_FAILED:
      return checkTranslatedQuestionsFailed(state, action.payload)

    case ADD_DESCRIPTIVE_QUESTION:
      return addDescriptiveQuestions(state, action.payload)

    case EDIT_DESCRIPTIVE_TEXT_HEADING:
      return editDescriptiveQuestionLable(state, action.payload)
    case ONCHANGE_QUESTION:
      return editDescriptiveText(state, action.payload)
    //
    case EDIT_SURVEY_MOVE_UP_OR_MOVE_DOWN_MULTIPLE_SELECTION_QUESTIONS:
      return moveUporMoveDownMultipleSelectedQuestions(state, action.payload)
    case EDIT_SURVEY_MULTIPLE_SELECTION_QUESTIONS_DRAG_AND_DROP:
      return multipleSelectionDragAndDrop(state, action.payload)
    case EDIT_SURVEY_ON_BLUR_MULTIPLE_SELECTION_HEADING:
      return onBlurMultipleSelectionHeading(state, action.payload)
    case EDIT_SURVEY_ON_BLUR_MULTIPLE_SELECTION_QUESTION_LABEL:
      return onBlurMultipleSelectionQuestionLabel(state, action.payload)
    case EDIT_SURVEY_ON_BLUR_MULTIPLE_SELECTION_ANSWER_OPTION:
      return onBlurMultipleSelectionAnswerOption(state, action.payload)
    case EDIT_SURVEY_DELETE_MULTIPLE_SELECTION_QUESTION_ANSWER:
      return deleteMultipleSelectionQuestionAnswer(state, action.payload)
    case EDIT_SURVEY_DELETE_MULTIPLE_SELECTION_QUESTION:
      return deleteMultipleSelectionQuestion(state, action.payload)
    case EDIT_SURVEY_ADD_MULTIPLE_SELECTION_QUESTION:
      return addMultipleSelectionQuestion(state, action.payload)
    case EDIT_SURVEY_ADD_MULTIPLE_SELECTION_QUESTIONS_ANSWER_OPTION:
      return addMultipleSelectionAnswerOption(state, action.payload)
    case ON_CHANGE_LOGO:
      return onChangeLogo(state, action.payload);

    case ADD_FOLLOW_UP_QUESTIONS:
      return addFollowUpQuestion(state, action.payload)
    case DELETE_FOLLOW_UP_QUESTIONS:
      return deleteFollowUpQuestion(state, action.payload);

    case ON_BLUR_OPEN_ENDED_DESCRIPTION_FOLLOW_UP_QUESTIONS:
      return onBlurOpenEndedDescription(state, action.payload)
    case ON_BLUR_OPEN_ENDED_HEADING_FOLLOW_UP_QUESTIONS:
      return onBlurOpenEndedHeading(state, action.payload);

    case ON_BLUR_MATRIX_FOLLOW_UP_QUESTIONS:
      return onBlurFollowUpMatrixQuestion(state, action.payload)
    case ON_CHANGE_FOLLOW_UP_MATRIX_QUESTION_SCALE:
      return onChangeMatrixQuestionScale(state, action.payload);
    case ON_CHANGE_FOLLOW_UP_QUESTION_CONDITION:
      return onChangeFollowUpQuestionCondition(state, action.payload);
    case ON_BLUR_OPEN_ENDED_HEADING_FOLLOW_UP_QUESTIONS_LEVEL:
      return onBlurQuestionLevelOpenEndedHeading(state, action.payload)
    case ON_BLUR_OPEN_ENDED_DESCRIPTION_FOLLOW_UP_QUESTIONS_LEVEL:
      return onBlurQuestionLevelOpenEndedDescription(state, action.payload);
    case ADD_QUESTION_LEVEL_MATRIX_FOLLOW_UP_QUESTIONS:
      return addQuestionLevelMatrixFollowUp(state, action.payload);
    case ADD_QUESTION_LEVEL_OPEN_ENDED_FOLLOW_UP_QUESTIONS:
      return addQuestionLevelOpenEndedFollowUp(state, action.payload);
    case DELETE_QUESTION_LEVEL_FOLLOW_UP:
      return deleteQuestionLevelFollowUp(state, action.payload);

    case FETCH_HRIS_FILTERS_START:
      return fetchHRISDataStart(state, action.payload);
    case FETCH_HRIS_FILTERS_SUCCESS:
      return fetchHRISDataSuccess(state, action.payload);
    case FETCH_HRIS_FILTERS_FAIL:
      return fetchHRISDataFail(state, action.payload);
    case ADD_CONDITIONAL_DISPLAY_TOPICS:
      return addConditionalDisplayTopics(state, action.payload)
    case ON_CHANGE_DISPLAY_CONDITION:
      return onChangeDisplayCondition(state, action.payload)
    case DELETE_CONDITIONAL_DISPLAY_TOPICS:
      return onDeleteDisplayCondition(state, action.payload)

    case SURVEY_EDITOR_LIKERT_DIMENSION_ORDESCRIPTION_CHANGE:
      return onChangeLikertDimensionOrDescription(state, action.payload)

    case SURVEY_EDITOR_LIKERT_QUESTION_CHANGE_FOLLOWUP_LABEL:
      return likertQuestionChangeFollowUpLabel(state, action.payload)

    case SURVEY_EDITOR_LIKERT_DRAG_AND_DROP:
      return likertDragAndDrop(state, action.payload)

    case SURVEY_EDITOR_LIKERT_REQUIRED_CHANGE:
      return likertRequiredChange(state, action.payload)
    case SURVEY_EDITOR_CHANGE_TOPIC:
      return likertShowTopicChange(state, action.payload)
    case SURVEY_EDITOR_LIKERT_CHANGE_OUTCOME_VARIABLE:
      return likertOutComeVariableChange(state, action.payload)
    case SURVEY_EDITOR_LIKERT_CHANGE_NA_OPTION:
      return likertNaOptionChange(state, action.payload)

    case SURVEY_EDITOR_TEXT_ENTRY_CHANGE_QUESTION_AND_LABEL:
      return onChangeTextEntryQuestionAndLabel(state, action.payload)

    case SURVEY_EDITOR_LIKERT_ADD_DISPLAY_CONDITION:
      return onSaveConditionalDisplayLikert(state, action.payload)
    case SURVEY_EDITOR_LIKERT_CHANGE_DISPLAY_CONDITION:
      return onDeleteDisplayConditionLikert(state, action.payload)
    case SURVEY_EDITOR_LIKERT_DELETE_DISPLAY_CONDITION:
      return onChangeDisplayConditionLikert(state, action.payload)

    case SURVEY_EDITOR_RANKING_DELETE_OR_ADD_OPTION:
      return rankingSectionAddOrDeleteOption(state, action.payload)

    case SURVEY_EDITOR_LIKERT_OPTION_CHANGE:
      return likertOptionChange(state, action.payload)
    case SURVEY_EDITOR_LIKERT_OPTION_CHANGE_LABEL:
      return likertOptionLabelChange(state, action.payload)
    case SURVEY_EDITOR_LIKERT_CHANGE_OPTIONS_COUNT:
      return likertChnageOptionsCount(state, action.payload)

    case SURVEY_EDITOR_LIKERT_QUESTION_LABEL_CHANGE:
      return likertQuestionLabelChange(state, action.payload)
    case SURVEY_EDITOR_LIKERT_QUESTION_ADD_FOLLOWUP:
      return likertQuestionAddFollowUp(state, action.payload)
    case SURVEY_EDITOR_LIKERT_QUESTION_DELETE_QUESTION:
      return likertQuestionDeleteQuestion(state, action.payload)
    case SURVEY_EDITOR_LIKERT_QUESTION_ADD_QUESTION:
      return likertQuestionAddQuestion(state, action.payload)
    case SURVEY_EDITOR_LIKERT_QUESTION_DELETE_FOLLOWUP:
      return likertQuestionDeleteFollowUp(state, action.payload)

    case SURVEY_EDITOR_OPTIONS_DRAG_END:
      return handleDragEndDemographicsOption(state, action.payload)
    case SURVEY_EDITOR_OPTIONS_DELETE:
      return handleDeleteOption(state, action.payload)
    case SURVEY_EDITOR_OPTIONS_ADD:
      return addMultipleChoiceOption(state, action.payload)
    case SURVEY_EDITOR_HANDLE_CHANGE_OPTIONS:
      return handleChangeLabel(state, action.payload)

    case SURVEY_EDITOR_HANDLE_MULTIOPTION_TOGGLE:
      return handleChangeMultiOptionToggle(state, action.payload)

    case SURVEY_EDITOR_LIKERT_QUESTION_CHANGE_FOLLOWUP_CONDITION:
      return likertQuestionChangeFollowUpCondition(state, action.payload)

    case SURVEY_EDITOR_ADD_QUESTIONS_FROM_LIBRARY:
      return handleAddFromLibrary(state, action.payload)

    case SURVEY_EDITOR_RATING_RIGHT_CHANGE_OPTION_COUNT_AND_SHAPE:
      return ratingSectionOptionCountAndShapeChange(state, action.payload)

    case SURVEY_EDITOR_RATING_RIGHT_CHANGE_QUESTION_AND_LABEL:
      return ratingSectionChangeQuestionAndQuestionLabel(state, action.payload)

    case SURVEY_EDITOR_RANKING_RIGHT_CHANGE_QUESTION_AND_LABEL:
      return rankingSectionChangeQuestionAndQuestionLabel(state, action.payload)

    case SURVEY_EDITOR_RANKING_DRAG_AND_DROP:
      return rankingSectionDragAndDrop(state, action.payload)

    case SURVEY_EDITOR_MATRIX_CHANGE_LABELS:
      return matrixLabelChange(state, action.payload)

    case SURVEY_EDITOR_MATRIX_ADD_ROW_OR_COL:
      return matrixAddColumOrRow(state, action.payload)

    case SURVEY_EDITOR_MATRIX_REMOVE_ROW_OR_COL:
      return matrixRemoveColumOrRow(state, action.payload)

    case SURVEY_EDITOR_ADD_BLOCK:
      return addQuestionBlock(state, action.payload)

    case SURVEY_EDITOR_HANDLE_CHANGE_QUESTION:
      return handleChangeMultiOptionChange(state, action.payload)
    case SURVEY_EDITOR_HANDLE_DELETE_BLOCK:
      return handleDeleteBlock(state, action.payload)
    case SURVEY_EDITOR_HANDLE_CREATE_DUPLICATE:
      return handleDuplicateBlock(state, action.payload)
    case SURVEY_EDITOR_HANDLE_BLOCK_DRAG_END:
      return handleBlockDragEnd(state, action.payload)

    case SURVEY_EDITOR_ADD_IMAGES_FROM_LIBRARY:
      return handleAddImage(state, action.payload)
    case SURVEY_EDITOR_NET_PROMOTER_CHANGE_QUESTION_AND_LABEL:
      return netPromoterSectionChangeQuestionAndQuestionLabel(state, action.payload)
    case SURVEY_EDITOR_DESCRIPTIVE_SECTION_CHANGE:
      return changeDescriptiveText(state, action.payload)


    case SURVEY_EDITOR_MATRIX_CHANGE_NA_OPTION:
      return handleNaOptionSelect(state, action.payload)

    case SURVEY_EDITOR_HANDLE_TOGGLE_WRITE_IN_OPTION:
      return handleChangeToggleWriteInOption(state, action.payload)
    case SURVEY_EDITOR_HANDLE_ADD_FOLLOWUP_QUESTION:
      return addFollowUpQuestionForMultiChoice(state, action.payload)
    case SURVEY_EDITOR_HANDLE_ADD_CONDITIONAL_FOLLOWUP:
      return addConditionalFollowUpForMultiChoice(state, action.payload)
    case SURVEY_EDITOR_HANDLE_MULTIPLE_CHOICE_CONDITIONAL_FOLLOWUP_LABEL:
      return multipleChoiceQuestionChangeFollowUpLabel(state, action.payload)
    case SURVEY_EDITOR_HANDLE_MULTIPLE_CHOICE_CONDITIONAL_FOLLOWUP:
      return multipleChoiceQuestionDeleteFollowUp(state, action.payload)
    case SURVEY_EDITOR_HANDLE_MULTIPLE_CHOICE_CONDITIONAL_FOLLOWUP_CHANGE:
      return multipleChoiceQuestionChangeFollowUpCondition(state, action.payload)

    case SURVEY_EDITOR_MULTIPLE_CHOICE_CHANGE_FOLLOWUP_LABEL:
      return multipleOptionFollowUpLabelChange(state, action.payload)

    case SURVEY_EDITOR_MULTIPLE_CHOICE_DELETE_FOLLOWUP:
      return multipleChoiceDeleteFollowUp(state, action.payload)

    case HANDLE_UPDATE_REDIRECT_VALUE_QUESTION:
      return redirectConditionValue(state, action.payload)



    case GET_NORM_BENCHMARK_VALUE_STARTED:
      return getBenchmarkDetailsStarted(state, action.payload);
    case GET_NORM_BENCHMARK_VALUE_SUCCESS:
      return getBenchmarkDetailsSuccess(state, action.payload);
    case GET_NORM_BENCHMARK_VALUE_FAILED:
      return getBenchmarkDetailsFail(state, action.payload);


    case SET_NORM_BENCHMARK_VALUE_STARTED:
      return setBenchmarkDetailsStarted(state, action.payload);
    case SET_NORM_BENCHMARK_VALUE_SUCCESS:
      return setBenchmarkDetailsSuccess(state, action.payload);
    case SET_NORM_BENCHMARK_VALUE_FAILED:
      return setBenchmarkDetailsFail(state, action.payload);

    case HANDLE_CHANGE_BENCHMARK_NORMS:
      return handleChangeBenchmarkNorms(state, action.payload)

    default:
      return { ...state };
  }
};

export default NewSurveyWizardReducer;
