import React from "react";
import Container from "../../Components/Container";
import { ButtonElement } from "../../FormElements/ButtonElement";
import { CustomTable } from "../../Components/CustomTable";
import TableLoader from "../../Components/ContentLoaders/TableLoader";
import constants from "./constants";
import "./index.scss";
import {
  GetLocalStorage,
} from "../../utils/localStorgeOperations";
import helpers from "./helpers";
import ModalWindow from "../../Components/CsvUploadModal";
import ConfirmationMessage from "../../Components/ConfirmationMessage";
import RenamePeople from "./RenamePeople";
import { getOrgIdFromUrl } from "../../utils/urlProcessor";
import { checkAccessStatus } from "../../utils/rolePermissionUtils";
import {PEOPLE_PERMISSIONS} from '../../../config/userPermissionConstants'
import { PEOPLE_PAGE_SECTION } from '../../../config/tabConstants';
import { Spin } from "antd";

let queueState = false

class PeopleList extends React.Component {
  constructor(props) {
    super(props);
    this.timeoutIds = "";
    this.state = {
      // checkBoxIsChecked:false,
      isModalVisible: false,
      hrisFilesList: [],
      isJobUploaded: false,
      sortClicked: false,
      search: "",
      hrisUsersPagination: 1,
      sortOrder: constants.sortHRIS.descend,
      peopleName: "",
      peopleDetails: [],
      intervalStarted: false,
      isInitialRender: false, 
      peopleId: "",
      confirmBoxOkLoading: false,
      queuedLength: false,
      initialLoader:true,
      org_Id:getOrgIdFromUrl(),
      selectedHrisType: "",
      pageLoader: false
    };
    this.props.fetchHRIS({
      currentTab: PEOPLE_PAGE_SECTION.PEOPLE,
      organizationId: getOrgIdFromUrl(),
      ...this.getParamsForPagination(0),
    }, this.disableLoader);
  }


  shouldComponentUpdate() {
    if (this.state.intervalStarted) {
      let statusCode = queueState
      queueState = false
      return statusCode

    }
    return true
  }


  componentDidUpdate() {
    if (
      this.props.fetchHRISApi.data !== null &&
      this.state.hrisFilesList !== this.props.fetchHRISApi.data.hrisFilesList
    ) {
      this.setState({
        hrisFilesList: this.props.fetchHRISApi.data.hrisFilesList,
        isJobUploaded: false,
      });
    }
    if (
      this.state.sortClicked === true &&
      !this.props.fetchHRISApi.isProcessing
    ) {
      this.setState({
        hrisFilesList: this.props.fetchHRISApi.data.hrisFilesList,
        sortClicked: false,
      });
    }
    if (
      this.state.isJobUploaded &&
      !this.props.fetchHRISApi.isProcessing &&
      this.props.fetchHRISApi.data !== null
    ) {
      this.interval = setInterval(() => {
        !this.state.queuedLength && this.setState({ isJobUploaded: true });
      }, 10000);
      this.props.fetchHRIS(this.getParamsForPagination(0));
      var queuedFiles = this.props.fetchHRISApi.data.hrisFilesList.filter(
        (item) => item.bulkJobStatus === "Queued"
      );

      if (queuedFiles.length < 1) {
        this.setState({ isJobUploaded: false, queuedLength: true });
      } else {
        this.setState({ isJobUploaded: true });
      }
    }
    else if(this.props.fetchHRISApi.data !== null) {
      // this.fetchHRISIfNeeded()
    }
       
  }

  componentDidMount() {
    this.setState({ isInitialRender: true });
    this.fetchHRISIfNeeded(null, true)
  }

  fetchHRISIfNeeded = (response, isFromRecallUpload) => {
    let foundListItemIndex
    if (!this.state.intervalStarted) {
      this.setState({ intervalStarted: true, initialLoader: false });
    }
    if ((response && response != null) || isFromRecallUpload) {
      if (isFromRecallUpload) {
        foundListItemIndex = -1
      }
      else {
        foundListItemIndex = response.findIndex(
          item => item.bulkJobStatus === "Queued" || item.bulkJobStatus === "Processing"
        );
      }
      if ((foundListItemIndex !== -1) || isFromRecallUpload) {
        setTimeout(() => {
          this.props.fetchHRIS(this.getParamsForPagination(isFromRecallUpload ? 0 : this.state.hrisUsersPagination - 1), (success, data) => {
            if (success) {
              if (!queueState) {
                queueState = true

              }
              this.setState({
                hrisFilesList: data.data.data.response.hrisFilesList,
              });
              this.fetchHRISIfNeeded(data && data.data && data.data.data && data.data.data.response && data.data.data.response.hrisFilesList)
            }

          })
        }, 4000)
      }
      else {
        this.setState({ intervalStarted: false });

      }
    }






    // if (this.state.hrisFilesList !== null) {
    //    let foundListItemIndex = this.state.hrisFilesList.findIndex(
    //      item => item.bulkJobStatus === "Queued" || item.bulkJobStatus === "Processing"
    //    );
    //   if ((foundListItemIndex !== -1 && !this.state.intervalStarted)) {
    //      this.interval = setInterval(() => {
    //        this.props.fetchHRIS(this.getParamsForPagination(0));
    //        let item = this.state.hrisFilesList[foundListItemIndex];
    //        if (item.bulkJobStatus === 'Completed') {
    //          clearInterval(this.interval);
    //          this.setState({ intervalStarted: false });
    //        }
    //      }, 2000);
    //      this.setState({ intervalStarted: true });
    //    }
    // }
   };

  //  componentWillUnmount() {
  //   clearInterval(this.interval);
  //  }

  onChangePagination = (value) => {
    this.setState({ hrisUsersPagination: value, pageLoader: true });
    const fromValue = (value - 1) * 12;
    this.props.fetchHRIS(this.getParamsForPagination(fromValue), (success, data) => {
      if (success) {
        this.setState({
          pageLoader: false
        })
        this.fetchHRISIfNeeded(data.data.data.response.hrisFilesList)

      }
    });
    if (this.state.pageLoader) {
      this.setState({
        pageLoader: false
      })
    }

  };

  getParamsForPagination = (from) => {
    return {
      currentTab: PEOPLE_PAGE_SECTION.PEOPLE,
      organizationId: getOrgIdFromUrl(),
      pagination: { from: from, size: 12 },
      searchText: this.state.search,
      sortField: this.state.sortField
        ? this.state.sortField
        : constants.sortHRIS.defaulSortKey,
      sortOrder: this.state.sortOrder
        ? this.state.sortOrder
        : constants.sortHRIS.defaultSortOrder,
    };
  };

  handleTableChange = (pagination, filters, sorter) => {
    let payload = {};
    if (sorter) {
      if (sorter.column && sorter.order) {
        let sorterColumnKey = sorter.columnKey;
        if (sorterColumnKey === "hrisFileName")
          sorterColumnKey = sorter.columnKey + constants.sortHRIS.keyword;
        payload.sortField = sorterColumnKey;
        payload.sortOrder = constants.sortHRIS[sorter.order];
        this.setState({
          sortField: sorterColumnKey,
          sortOrder: constants.sortHRIS[sorter.order],
          hrisUsersPagination: 1,
        });
      } else {
        if (sorter.columnKey === constants.sortHRIS.defaulSortKey) {
          payload.sortField = sorter.columnKey;
          payload.sortOrder = constants.sortHRIS.ascend;
          this.setState({
            sortField: constants.sortHRIS.defaulSortKey,
            sortOrder: constants.sortHRIS.asc,
            hrisUsersPagination: 1,
          });
        } else {
          this.setState({
            sortField: constants.sortHRIS.defaulSortKey,
            sortOrder: constants.sortHRIS.defaultSortOrder,
            hrisUsersPagination: 1,
          });
        }
      }
      this.props.fetchHRIS({
        currentTab: PEOPLE_PAGE_SECTION.PEOPLE,
        organizationId: getOrgIdFromUrl(),
        pagination: { from: 0, size: 12 },
        ...payload,
      });
      this.setState({
        sortClicked: true,
        hrisUsersPagination: 1,
      });
    }
  };
  showUploadHrisModal = () => {
    this.props.toggleBulkModal(true);
  };

  handleModalState = (cb) => {
    this.props.toggleBulkModal(false);
    this.props.handleFileUpload(null)
    cb('')
  };
 addJob = (uploadedFileName, keyName, fileName, cb) => {
    this.props.addJob(
      {
        currentTab: PEOPLE_PAGE_SECTION.PEOPLE,
        keyName,
        hrisFileName: fileName,
        hrisFileType:  this.state.selectedHrisType ? this.state.selectedHrisType : undefined,
        uploadedFileName,
        organizationId: this.fetchRouteParam(),
        ...helpers.getParamsForAddJob(),
      },
      (isSuccess) => {
        if (isSuccess) {
          cb && cb('')
          this.props.toggleBulkModal()
          this.props.handleFileUpload(null)
          this.setState({ isModalVisible: false, isJobUploaded: true, hrisUsersPagination: 1, });
          this.fetchHRISIfNeeded(null, true)
        } else {
          cb && cb('')
          this.props.toggleBulkModal()
          this.props.handleFileUpload(null)
          this.setState({ isModalVisible: false });
        }
      }
    );
  };

  makeApiParams = (itemObject, hrisFileId) => {
    return this.props.isMainUser
      ? itemObject
      : {
          hrisFileId: hrisFileId,
          organizationId: this.fetchRouteParam(),
          ...itemObject,
        };
  };
  fetchRouteParam = () => {
    return this.props.match.params.org_id;
  };
  submitToUpload = (cb, fileName) => {
    helpers.submitToUpload(this.props, {
      addJob: this.addJob,
      fileName: fileName,
      cb: cb,
    });
  };
  visibleToggle = (key, value) => {
    this.setState({ [key]: !this.state[key] });
    this.setState({ peopleDetails: value });
    if (value)
      this.setState({
        peopleName: value.hrisFileName,
        peopleId: value.hrisFileId,
      });
  };
  confirmBoxCancel = () => {
    this.setState({ deletePeople: false });
  };
  callbackDelete = (value) => {
    this.setState({ deletePeople: false });
    this.props.fetchHRIS({
      organizationId: getOrgIdFromUrl(),
      ...this.getParamsForPagination(0),
    },this.disableLoader);
    
  };

  confirmBoxOk =  () => {
    this.setState({ confirmBoxOkLoading: true });
   
      const params = {
        currentTab: PEOPLE_PAGE_SECTION.THREE_DOT_MENU.DELETE,
        userId: GetLocalStorage("userId"),
        userEmail: GetLocalStorage("username"),
        hrisFileId: this.state.peopleId,
      };

     // const resp = await PeopleListActions.deletePeople(params);
     this.props.deletePeople(params,this.callbackDelete);
     
    //  this.setState({ confirmBoxOkLoading: false });
   
  };

  confirmationMessage = () => {
    return (
      <ConfirmationMessage
        visibleConfirm={this.state.deletePeople}
        confirmBoxCancel={this.confirmBoxCancel}
        onOk={this.confirmBoxOk}
        onCancel={this.confirmBoxCancel}
        heading={`Are you sure you want to delete the ${this.state.peopleName} HRIS?`}
        confirmButtonLabel={"Yes"}
        loading={this.props.deletePeopleApi.isProcessing}
        cancelButtonLabel={"No"}
        deleteHeader={constants.DELETE_HEADER}
      ></ConfirmationMessage>
    );
  };
  confirmRenameBoxCancel = () => {
    this.setState({ renamePeople: false });
  };
  onClickRename = () => {
    this.setState({ renamePeople: false });
  };
  renameHandleChange = (value) => {
    this.setState({ peopleName: value });
  };
  callbackRename = (value) => {
    this.setState({ renamePeople: false });
    this.props.fetchHRIS({
      organizationId: getOrgIdFromUrl(),
      ...this.getParamsForPagination(0),
    },this.disableLoader);
  };
  disableLoader = () =>
  {
    this.setState({ confirmBoxOkLoading: false,initialLoader:false });
  }
  confirmRenameBoxOk =  () => {
    this.setState({ confirmBoxOkLoading: true });
   
      const params = {
        currentTab: PEOPLE_PAGE_SECTION.THREE_DOT_MENU.RENAME,
        userId: GetLocalStorage("userId"),
        userEmail: GetLocalStorage("username"),
        hrisFileName: this.state.peopleName,
        hrisFileId: this.state.peopleId,
      };
      this.props.renamePeople(params,this.callbackRename);
           
  };

//   handleCheckChange = (value) =>{
//     this.setState({ checkBoxIsChecked: value.value });
// }

  handleOptionChange = (value) =>{
    this.setState({ selectedHrisType: value });

  }
  RenamePeoplePopUp = () => {
    
    return (
      <RenamePeople
        visibleConfirm={this.state.renamePeople}
        confirmBoxCancel={this.confirmRenameBoxCancel}
        onOk={this.confirmRenameBoxOk}
        onCancel={this.confirmRenameBoxCancel}
        heading={"Rename HRIS File"}
        confirmButtonLabel={"Yes"}
        loading={this.props.renamePeopleApi.isProcessing}
        peopleName={this.state.peopleName}
        onClickRename={this.renameHandleChange}
      ></RenamePeople>
    );
  };
  render() {
    let loader = this.state.confirmBoxOkLoading && this.props.fetchHRISApi.isProcessing
    return (
      <div className="people-list">
        <div className="people-list__header">
          <Container>
            <div className="header-block">
              <h2>People uploads</h2>
              {checkAccessStatus(this.state.org_Id,PEOPLE_PERMISSIONS.UPLOAD_HRIS)?
              <ButtonElement
                className="header-btn"
                onClick={this.showUploadHrisModal}
                children=" Upload new HRIS data"
              />:null}
            </div>
          </Container>
        </div>
        <div className="people-list__content">
          <Container>
            <Spin spinning={this.state.pageLoader}>
              {this.props.fetchHRISApi.data !== null && !this.state.initialLoader ? (
              <>
                <CustomTable
                  className="people-table"
                    dataSource={this.state.hrisFilesList}
                  columns={constants.tableColumn(
                    this.props,
                    this.visibleToggle
                  )}
                  pagination={false}
                  onChange={this.handleTableChange}
                  loading={ loader || this.state.initialLoader}
                />
                {this.confirmationMessage()}
                {this.RenamePeoplePopUp()}
              </>
            ) : (
              <TableLoader />
            )}
            {helpers.getPageDivForRenderUsersList({
              fetchHRISApi: this.props.fetchHRISApi,
              page: this.state.hrisUsersPagination,
              onChange: this.onChangePagination,
              item: "HRIS File(s)",
            })}
            <div id="managers-modalMount" className="managers-modalMount">
              <ModalWindow
                handleModalState={(cb) => this.handleModalState(cb)}
                submitToUpload={this.submitToUpload}
                {...this.props}
                // handlechange = {this.handleCheckChange}
                // checkBoxIsChecked ={this.state.checkBoxIsChecked}
                // showCheckBox = {true}
                isFromPeopleTab = {true}
                handleOptionChange = {this.handleOptionChange}
              />
            </div>
            </Spin>
          </Container>
        </div>
      </div>
    );
  }
}
export default PeopleList;
